import React, { Fragment } from "react";
import Sidebar from "./components/sidebar.component";
import Footer from "./components/footer.component";
import Navbar from "./components/navbar.component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DOMAIN_SERVER } from "../utils/states";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "profile",
      oldPassword: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      email: "",
      avatarLocation: "gravatar",
      avatarFile: null,
      created_at: "",
      updated_at: "",
    };
  }

  componentDidMount() {
    this.fetchUserProfile();
  }

  fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${DOMAIN_SERVER}/api/user-profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const user = response.data.user;
      this.setState({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        avatarLocation: user.avatar_location,
        created_at: user.created_at,
        updated_at: user.updated_at,
      });
    } catch (error) {
      localStorage.clear();
      window.location.href = "/";
      console.error("Error fetching user profile:", error);
    }
  };

  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFileChange = (e) => {
    this.setState({ avatarFile: e.target.files[0] });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("Password Updated", this.state);
  };

  handleUpdateSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, avatarLocation, avatarFile } =
      this.state;

    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("avatar_type", avatarLocation);
    if (avatarFile) {
      formData.append("avatar_location", avatarFile);
    }

    try {
      const response = await axios.post(
        `${DOMAIN_SERVER}/api/update-profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Profile updated successfully");
      this.fetchUserProfile(); // Refresh profile data
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile");
    }
  };

  renderProfileTab = () => {
    const { firstName, lastName, email, created_at, updated_at } = this.state;
    return (
      <div className="mt-3">
        <table className="table">
          {email !== "" && (
            <tbody>
              <tr>
                <th>Avatar</th>
                <td>
                  <img
                    src="https://www.gravatar.com/avatar/64e1b8d34f425d19e1ee2ea7236d3028.jpg?s=80&d=mm&r=g"
                    alt="Avatar"
                    className="img-thumbnail"
                  />
                </td>
              </tr>
              <tr>
                <th>Name</th>
                <td>
                  {firstName} {lastName}
                </td>
              </tr>
              <tr>
                <th>E-mail</th>
                <td>{email}</td>
              </tr>
              <tr>
                <th>Created At</th>
                <td>{new Date(created_at).toString()}</td>
              </tr>
              <tr>
                <th>Last Updated</th>
                <td>{new Date(updated_at).toString()}</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    );
  };

  renderUpdateInformationTab = () => {
    return (
      <div className="mt-3">
        <form onSubmit={this.handleUpdateSubmit}>
          <div className="form-group">
            <label>Avatar Location</label>
            <div>
              <input
                type="radio"
                name="avatarLocation"
                value="gravatar"
                checked={this.state.avatarLocation === "gravatar"}
                onChange={this.handleChange}
              />{" "}
              Gravatar
              <input
                type="radio"
                name="avatarLocation"
                value="upload"
                className="ml-3"
                checked={this.state.avatarLocation === "upload"}
                onChange={this.handleChange}
              />{" "}
              Upload
            </div>
          </div>

          {this.state.avatarLocation === "upload" && (
            <div className="form-group">
              <input
                type="file"
                className="form-control"
                name="avatarFile"
                onChange={this.handleFileChange}
              />
            </div>
          )}

          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              value={this.state.firstName}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              value={this.state.lastName}
              onChange={this.handleChange}
              required
            />
          </div>

          <div className="alert alert-info">
            <i className="fa fa-info-circle"></i> If you change your e-mail you
            will be logged out until you confirm your new e-mail address.
          </div>

          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              className="form-control"
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
          </div>

          <button type="submit" className="btn btn-success">
            Update
          </button>
        </form>
      </div>
    );
  };

  renderChangePasswordTab = () => {
    return (
      <div className="mt-3">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label>Old Password</label>
            <input
              type="password"
              name="oldPassword"
              className="form-control"
              placeholder="Old Password"
              value={this.state.oldPassword}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Password Confirmation</label>
            <input
              type="password"
              name="confirmPassword"
              className="form-control"
              placeholder="Password Confirmation"
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-success">
            Update Password
          </button>
        </form>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <div id="wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar current="profile" />
              <div className="container-fluid">
                <div className="col-lg-8 mb-4" style={{ marginLeft: "9%" }}>
                  <div className="card mt-4">
                    <div className="card-header font-weight-bold">
                      My Account
                    </div>
                    <div className="card-body">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "profile" ? "active" : ""
                            }`}
                            href="#"
                            onClick={() => this.handleTabChange("profile")}
                          >
                            Profile
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "updateInformation"
                                ? "active"
                                : ""
                            }`}
                            href="#"
                            onClick={() =>
                              this.handleTabChange("updateInformation")
                            }
                          >
                            Update Information
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              this.state.activeTab === "changePassword"
                                ? "active"
                                : ""
                            }`}
                            href="#"
                            onClick={() =>
                              this.handleTabChange("changePassword")
                            }
                          >
                            Change Password
                          </a>
                        </li>
                      </ul>
                      {this.state.activeTab === "profile" &&
                        this.renderProfileTab()}
                      {this.state.activeTab === "updateInformation" &&
                        this.renderUpdateInformationTab()}
                      {this.state.activeTab === "changePassword" &&
                        this.renderChangePasswordTab()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  }
}

function ProfilePage() {
  const navigate = useNavigate();
  return <Profile navigate={navigate} />;
}

export default ProfilePage;
