import React, { Fragment } from "react";

import Sidebar from "./components/sidebar.component";
import Footer from "./components/footer.component";
import Navbar from "./components/navbar.component";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DOMAIN, DOMAIN_SERVER, states } from "../utils/states";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import giphy from "../pages/assets/img/giphy.gif";
const ExcelJS = require("exceljs");

const regex = /([\d\w.]+@[\d\w.-]+\.\w+)/g;

class ExcelExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      query: "",
      filteredCities: [],
      canScrap: false,
      foundLeads: 0,
      totalLeads: 0,

      states: [],
      state: "",
      selectedState: null,
      cityOptions: [],
      selectedCity: null,
      currentCity: "",
      currentCityNumber: 0,
      currentState: "",
      currentQuery: "",

      selectAllCities: false,
      uniqueSearch: false,
      withLatLongSearch: false,
      withEmailSearch: false,

      currentEmailSyncCursor: 0,
    };
  }

  async componentDidMount() {
    const query = localStorage.getItem("query");

    axios
      .get(`${DOMAIN}/api/fetch-states`)
      .then((response) => {
        response.data = response.data.sort((a, b) => {
          if (a.state < b.state) return -1;
          if (a.state > b.state) return 1;
          return 0;
        });

        const statesOptions = response.data.map((state) => ({
          label: state.state,
          value: state.state,
        }));
        this.setState({
          states: statesOptions,
          query: query,
          loading: false,
        });
      })
      .catch((error) => {
        // alert("Unable to fetch states data!");
        console.log("Error fetching states:", error);
      });
  }

  convertSecondsToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  setStateAsync = (component, newState) => {
    return new Promise((resolve) => {
      component.setState(newState, () => {
        resolve();
      });
    });
  };

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  handleClick = () => {
    try {
      const query = this.state.query;
      const state = this.state.selectedState.label;
      const cities = this.state.selectedCity.map((e) => e.value);

      if (query === "" || state === "") {
        alert("Please select all the required fields");
        return false;
      }

      this.setState({ canScrap: true }, async () => {
        let allData = []; // Collect all city data

        for (let index = 0; index < cities.length; index++) {
          var city = cities[index];
          var encode = encodeURIComponent(`${city}, ${state} ${query}`);

          try {
            var response = await fetch(
              `${DOMAIN}/scrape/${city}/${state}/${query}/${encode}/1`
            );

            await this.setStateAsync(this, {
              currentCity: city,
              currentCityNumber: index,
              currentState: state,
              currentQuery: query,
            });

            var reader = response.body.getReader();
            let chunks = "";
            let cityData = []; // Store data for the current city

            while (true) {
              let raw = "";

              const { done, value } = await reader.read();
              chunks += new TextDecoder("utf-8").decode(value);

              var lines = chunks.split("\n");

              let incompletePart = "";
              if (lines.length > 1) {
                for (let index = 0; index < lines.length; index++) {
                  var element = lines[index];
                  if (element?.length > 0) {
                    element = incompletePart + element;

                    try {
                      element = JSON.parse(element);
                      incompletePart = "";
                    } catch (error) {
                      incompletePart = element;
                      continue;
                    }

                    if ("total" in element) {
                      this.setState({ totalLeads: element.total });
                    } else {
                      if (cities.length > 1) {
                        element.bulkCitiesSearch = true;
                        element.query = `${
                          this.state.selectAllCities === true
                            ? `all-cities | ${state} | ${query}`
                            : cities.join(", ")
                        } | ${state} | ${query}`;
                      } else {
                        element.bulkCitiesSearch = false;
                      }
                      cityData.push(element);
                    }
                  }
                }
              }

              if (done) {
                break;
              }
            }

            allData = [
              ...new Map(
                [...allData, ...cityData].map((item) => [item.id, item])
              ).values(),
            ];

            // Update foundLeads **after each city is processed**
            this.setState({ foundLeads: allData.length });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }

        // Generate and download the Excel file after all cities are processed
        try {
          if (allData.length > 0) {
            // Remove duplicates if uniqueSearch is enabled
            if (this.state.uniqueSearch) {
              const uniqueDataMap = new Map();
              allData.forEach((item) => {
                if (!uniqueDataMap.has(item.id)) {
                  uniqueDataMap.set(item.id, item);
                }
              });
              allData = Array.from(uniqueDataMap.values()); // Convert back to array
            }

            // Filter data to keep only entries with lat & lng if withLatLongSearch is enabled
            if (this.state.withLatLongSearch) {
              allData = allData.filter(
                (item) =>
                  item?.lat !== undefined &&
                  item?.lng !== undefined &&
                  item?.lat !== null &&
                  item?.lng !== null &&
                  item?.lat !== "" &&
                  item?.lng !== ""
              );
            }

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Leads");

            let headers = null;

            // Email scraper starts
            if (this.state.withEmailSearch) {
              function isValidURL(url) {
                try {
                  new URL(url);
                  return true;
                } catch (e) {
                  return false;
                }
              }

              var chunkSize = 25; // Number of concurrent requests
              for (let i = 0; i < allData.length; i += chunkSize) {
                var chunk = allData.slice(i, i + chunkSize); // Get a chunk of 25

                await Promise.all(
                  chunk.map(async (element, index) => {
                    await this.setStateAsync(this, {
                      currentEmailSyncCursor: i + index + 1, // Track progress
                    });

                    if (isValidURL(element.website)) {
                      try {
                        let url = new URL(element.website);
                        let domain = url.origin;

                        var scrapeResponse = await axios.post(
                          `${DOMAIN}/scrape-email`,
                          {
                            id: element.id,
                            domain,
                          }
                        );

                        element.emails =
                          scrapeResponse.data.emails[0].emails.join(", ");
                      } catch (error) {
                        console.error(
                          "Error processing URL for email:",
                          element.website
                        );
                      }
                    }
                  })
                );
              }
            }
            // Email scraper ends

            const transformedData = allData.map((item) => {
              return {
                Id: item?.id,
                "Company Name": item.title,
                Niche: item.niche,
                Rating: item.rating,
                Reviews: item.reviews,
                Website: item.website,
                GMB: item.gmb,
                Phone: item.phone,
                Address: item.address,
                Street: item.street,
                City: item.city,
                State: item.state,
                Zip: item.zip,
                Latitude: item?.lat,
                Longitude: item?.lng,
                Emails:
                  this.state.withEmailSearch && item?.emails?.length > 0
                    ? item?.emails
                    : undefined,
              };
            });

            transformedData.forEach((item) => {
              delete item.title;
              delete item.emailSync;
              delete item.social;
              delete item._id;
              delete item.folder;
              delete item.user;
              delete item.query;
              delete item.bulkCitiesSearch;
              delete item.lat;
              delete item.lng;
              delete item.emails;
            });

            if (!headers && transformedData.length > 0) {
              headers = Object.keys(transformedData[0]);
              worksheet.addRow(headers).commit();
            }

            transformedData.forEach((row) => {
              worksheet.addRow(Object.values(row)).commit();
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
              type: "application/octet-stream",
            });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${state.toLowerCase()}_cities_updated.xlsx`;
            link.click();
          }
        } catch (error) {
          alert(`Error fetching data, please try again!`);
        }

        this.setState({ canScrap: false });
        alert("Leads fetched successfully!");
      });
    } catch (error) {
      console.log(error);
    }
  };

  filteredCitiesHandler = (e) => {
    var state = e.target.value;
    var filteredCities = states[state];
    this.setState({ state, filteredCities }, async () => {
      localStorage.setItem("state", this.state.state);
    });
  };

  fetchEmails = async (iterations, index, query) => {
    var n = iterations[index];

    var _data = await axios.post(
      `https://grab-map-leads-node.vercel.app/email-scraper`,
      {
        query,
        start: n,
      }
    );

    return _data.data;
  };

  runIterations = async (query) => {
    var results = [];

    var iterations = [0, 10];

    for (let index = 0; index < iterations.length; index++) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 10000 * index));

        const iterationResult = await this.fetchEmails(
          iterations,
          index,
          query
        );
        if (iterationResult?.length !== 0) {
          results = results.concat(iterationResult);
        }
      } catch (error) {
        console.log(error);
      }
    }
    return results;
  };

  handleStateChange = (selectedOption) => {
    this.setState(
      {
        selectedState: selectedOption,
        cityOptions: [],
        selectedCity: null,
        loading: true,
        selectAllCities: false,
      },
      () => {
        if (selectedOption) {
          axios
            .get(`${DOMAIN}/api/fetch-cities/${this.state.selectedState.label}`)
            .then((response) => {
              response.data = response.data.sort((a, b) => {
                if (a.city < b.city) return -1;
                if (a.city > b.city) return 1;
                return 0;
              });

              // const filteredCities = response.data.filter(
              //   (city) => city.state === selectedOption.value
              // );
              const cityOptions = response.data.map((city) => ({
                label: city.city,
                value: city.city,
              }));

              this.setState({
                cityOptions: cityOptions,
                loading: false,
                selectAllCities: false,
              });
            })
            .catch((error) => {
              alert("Unable to fetch states data!");
              console.error("Error fetching cities:", error);
            });
        }
      }
    );
  };

  handleSelectAllCities = (e) => {
    console.log(this.state.cityOptions.length);

    this.setState({
      selectedCity: e.target.checked === true ? this.state.cityOptions : [],
      selectAllCities: e.target.checked,
    });
  };

  handleCityChange = (selectedOption) => {
    this.setState({ selectedCity: selectedOption });
  };

  render() {
    const { totalLeads, foundLeads } = this.state;
    TopBarProgress.config({
      barColors: {
        0: "#CB3837",
        0.5: "#C836C3",
        "1.0": "#29ABE2",
      },
      shadowBlur: 5,
    });
    return (
      <Fragment>
        <div id="wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar current="excel-export" />

              <div className="container-fluid" style={{ height: "100vh" }}>
                {this.state.loading && <TopBarProgress />}
                <div
                  className="d-sm-flex align-items-center justify-content-between mb-4"
                  style={{ marginLeft: "10%" }}
                >
                  <h1 className="h3 mb-0 text-gray-800">Excel Export</h1>
                </div>
                <div
                  className={`row ${this.state.loading ? "disabled" : ""}`}
                  style={{ marginLeft: "9%" }}
                >
                  <div className="col-lg-6 mb-4">
                    <div className="card shadow mb-4">
                      <div className="card-body">
                        <div className="form-group">
                          <label style={{ fontWeight: "bold" }}>Niche</label>
                          <input
                            type="text"
                            value={this.state.query}
                            placeholder="Niche - e. g. Roofer"
                            className="form-control"
                            onChange={(e) => {
                              this.setState(
                                { query: e.target.value },
                                async () => {
                                  localStorage.setItem(
                                    "query",
                                    this.state.query
                                  );
                                }
                              );
                            }}
                          />
                        </div>

                        <div className="form-group">
                          <label style={{ fontWeight: "bold" }}>
                            Select State
                          </label>
                          <Select
                            options={this.state.states}
                            onChange={this.handleStateChange}
                            value={this.state.selectedState}
                            placeholder="Select a state"
                            isDisabled={this.state.query === "" ? true : false}
                          />
                        </div>

                        <div className="form-group">
                          <label style={{ fontWeight: "bold" }}>
                            Select Cities
                          </label>
                          <Select
                            options={
                              this.state.selectAllCities === true
                                ? []
                                : this.state.cityOptions
                            }
                            onChange={this.handleCityChange}
                            value={
                              this.state.selectAllCities === true
                                ? []
                                : this.state.selectedCity
                            }
                            isDisabled={
                              this.state.selectAllCities === true
                                ? true
                                : !this.state.selectedState
                            }
                            placeholder="Select a city"
                            isMulti
                          />

                          <div className="form-check" style={{ marginTop: 14 }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={this.state.selectAllCities}
                              checked={this.state.selectAllCities}
                              id="selectAllCities"
                              onChange={(e) => {
                                this.handleSelectAllCities(e);
                              }}
                              disabled={!this.state.selectedState}
                            />
                            <label
                              className="form-check-label"
                              for="selectAllCities"
                            >
                              Select all cities of state
                            </label>
                          </div>

                          <div className="form-check" style={{ marginTop: 14 }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={this.state.uniqueSearch}
                              checked={this.state.uniqueSearch}
                              id="uniqueSearch"
                              onChange={(e) => {
                                this.setState({
                                  uniqueSearch: e.target.checked,
                                });
                              }}
                              disabled={!this.state.selectedState}
                            />
                            <label
                              className="form-check-label"
                              for="uniqueSearch"
                            >
                              Remove Duplicates
                            </label>
                          </div>

                          <div className="form-check" style={{ marginTop: 14 }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={this.state.withLatLongSearch}
                              checked={this.state.withLatLongSearch}
                              id="withLatLongSearch"
                              onChange={(e) => {
                                this.setState({
                                  withLatLongSearch: e.target.checked,
                                });
                              }}
                              disabled={!this.state.selectedState}
                            />
                            <label
                              className="form-check-label"
                              for="withLatLongSearch"
                            >
                              With Latitude and Longitude Search
                            </label>
                          </div>

                          <div className="form-check" style={{ marginTop: 14 }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={this.state.withEmailSearch}
                              checked={this.state.withEmailSearch}
                              id="withEmailSearch"
                              onChange={(e) => {
                                this.setState({
                                  withEmailSearch: e.target.checked,
                                });
                              }}
                              disabled={!this.state.selectedState}
                            />
                            <label
                              className="form-check-label"
                              for="withEmailSearch"
                            >
                              With Email Search
                            </label>
                          </div>
                        </div>

                        <div>
                          <button
                            className="btn btn-info btn-sm floatLeft"
                            style={{
                              background: "green",
                            }}
                            disabled={this.state.canScrap ? true : false}
                            onClick={this.handleClick}
                          >
                            Find Leads
                          </button>
                          <br />
                          <br />
                          <div>
                            {this.state.canScrap === true && totalLeads > 0 ? (
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    float: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      float: "right",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Searching Leads:
                                  </span>

                                  <span style={{ float: "right" }}>
                                    - Current Query: {this.state.currentQuery}
                                  </span>

                                  <span style={{ float: "right" }}>
                                    - Current Searching State:{" "}
                                    {this.state.currentState}
                                  </span>

                                  <span style={{ float: "right" }}>
                                    - Current Searching City:{" "}
                                    {this.state.currentCity}
                                  </span>

                                  <span style={{ float: "right" }}>
                                    - City Count:{" "}
                                    {this.state.currentCityNumber + 1} /{" "}
                                    {this.state.selectedCity.length}
                                  </span>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    float: "right",
                                  }}
                                >
                                  <span
                                    style={{
                                      float: "right",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Fetching Leads Progress:
                                  </span>

                                  <span style={{ float: "right" }}>
                                    - Leads Fetched: {foundLeads}
                                    {/* / {totalLeads} */}
                                  </span>

                                  <span style={{ marginTop: 5 }}>
                                    - Estimated Time:{" "}
                                    {this.convertSecondsToMinutes(totalLeads)}
                                  </span>

                                  <span style={{ marginTop: 5 }}>
                                    - Current Email Sync Cursor:{" "}
                                    {this.state.currentEmailSyncCursor}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-4">
                    {this.state.canScrap ? (
                      <div className="card shadow mb-4">
                        <img src={giphy} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Fragment>
    );
  }
}

function ExcelExportPage() {
  const navigate = useNavigate();

  return <ExcelExport navigate={navigate} />;
}

export default ExcelExportPage;
