import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard/dashboard";
import Setting from "./pages/setting";
import ExcelExport from "./pages/excelExport";
import Crm from "./pages/crm/crm";
import Integration from "./pages/integration/integration";
import Folders from "./pages/folders/folders";
import Support from "./pages/support/support";
import ProfilePage from "./pages/profile";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/search" element={<Setting />} />
          <Route path="/crm" element={<Crm />} />
          <Route path="/integrations" element={<Integration />} />
          <Route path="/folders" element={<Folders />} />
          <Route path="/support" element={<Support />} />
          <Route path="/excel-export" element={<ExcelExport />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
