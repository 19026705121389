import React, { Fragment } from "react";
import * as EmailValidator from "email-validator";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DOMAIN_SERVER } from "../utils/states";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      email:
        localStorage.getItem("email") !== null
          ? localStorage.getItem("email")
          : "",
      password: "",
      sendState: "",
    };
  }

  _emailChangeHandler = (event) => {
    this.setState({ email: event.target.value.trim() }, () => {
      localStorage.setItem("email", this.state.email);
    });
  };
  _passwordChangeHandler = (event) => {
    this.setState({ password: event.target.value.trim() });
  };

  handleClick(e) {
    e.preventDefault();
    this.setState({ loading: true });
    localStorage.setItem("email", this.state.email);
    localStorage.setItem("password", this.state.password);
    if (this.state.email == "") {
      this.setState({ error: "Please provide valid email", sendState: "" });
    } else if (this.state.password == "") {
      this.setState({ error: "Please enter password", sendState: "" });
    } else if (EmailValidator.validate(this.state.email) == false) {
      this.setState({ error: "Email is not valid", sendState: "" });
    } else if (
      EmailValidator.validate(this.state.email) == true &&
      this.state.password != ""
    ) {
      this.setState({ sendState: "loading" });
      var bodyFormData = new FormData();
      bodyFormData.set("email", this.state.email);
      bodyFormData.append("password", this.state.password);
      axios({
        method: "post",
        url: `${DOMAIN_SERVER}/api/login`,
        data: bodyFormData,
        config: {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        },
      })
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("user_id", res.data.success.user.id);
            localStorage.setItem("token", res.data.success.token);

            this.setState({ error: null, sendState: "finished" }, () => {
              this.props.navigate("/dashboard");
            });
          }
          if (res.data.error) {
            this.setState({
              error: "Invalid credentials",
              sendState: "",
              loading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            error: err.response.data.error,
            sendState: "",
            loading: false,
          });
        });
    }
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.handleClick(event);
    }
  }
  render() {
    return (
      <Fragment>
        <div className="container">
          {/* Outer Row */}
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  {/* Nested Row within Card Body */}
                  <div className="row" style={{ height: 432 }}>
                    <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">
                            Welcome Back!
                          </h1>
                        </div>
                        <form className="user">
                          {this.state.error && (
                            <div
                              className="d-flex links"
                              style={{
                                color: "red",
                                padding: 14,
                                fontWeight: "bold",
                              }}
                            >
                              {this.state.error}
                            </div>
                          )}
                          <div className="form-group">
                            <input
                              style={{
                                borderColor: this.state.error ? "red" : "",
                                borderWidth: this.state.error ? 2 : 1,
                              }}
                              type="email"
                              value={this.state.email}
                              onChange={this._emailChangeHandler}
                              className="form-control form-control-user"
                              id="exampleInputEmail"
                              placeholder="Email"
                              onKeyPress={this.enterPressed}
                            />
                          </div>

                          <div className="form-group">
                            <input
                              style={{
                                borderColor: this.state.error ? "red" : "",
                                borderWidth: this.state.error ? 2 : 1,
                              }}
                              type="password"
                              value={this.state.password}
                              onChange={this._passwordChangeHandler}
                              className="form-control form-control-user"
                              id="exampleInputPassword"
                              placeholder="Password"
                              onKeyPress={this.enterPressed.bind(this)}
                            />
                          </div>

                          <div className="form-group"></div>

                          <a
                            href="#"
                            state={this.state.sendState}
                            className="btn btn-primary btn-user btn-block"
                            onClick={(e) => {
                              this.handleClick(e);
                            }}
                          >
                            Login
                          </a>
                        </form>
                        <hr />
                        <div className="text-center">
                          <div className="form-group">
                            <div className="form-group text-right">
                              <a
                                href={`${DOMAIN_SERVER}/password/reset/form`}
                                target="_blank"
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                Forgot Your Password?
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function LoginPage() {
  const navigate = useNavigate();

  return <Login navigate={navigate} />;
}

export default LoginPage;
